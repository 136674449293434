import { Card, CardContent, CardHeader, CardMedia, Collapse, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Album } from "../../../types/Album";
import { Song } from "../../../types/Song";

var cardStyle = {
    display: 'block',
    margin: 5,
    width: '40vw',
    transitionDuration: '0.3s',
    //height: '15vw'
}

var imageStyle = {
    media: {
        height: 350,
        width: 350,
        margin: 'auto'
    }
}

var headerStyle = {
    color: "orange"
}

interface AlbumCardProps {
    album: Album
    songs: Array<Song>
}

export default function AlbumCard(props: AlbumCardProps) {
    const album = props.album
    const songs = props.songs
    const albumSongList = album.songList
    const [albumSongs, setAlbumSongs] = useState<Array<Song>>([])
    const [checked, setChecked] = useState<boolean>(false)

    useEffect(() => {
        if (songs.length >= 0) {
            let albumSongs = songs.filter(song => albumSongList.includes(song.rowKey))
            setAlbumSongs(albumSongs)
        }
    }, [songs, albumSongList])


    function handleChange() {
        setChecked((prev) => !prev)
    }

    return (
        <Card variant="outlined" style={cardStyle} id={album.rowKey}>
            <CardHeader style={headerStyle} key={album.rowKey} title={album.title} />

            <CardContent>
                {album.coverImage &&
                    <img
                        style={imageStyle.media}
                        src={`/api/media/images/${album.coverImage}`}
                        title={`${album.title} - Cover`}
                    />
                }
                {album.innerImage &&
                    <img
                        style={imageStyle.media}
                        src={`/api/media/images/${album.innerImage}`}
                        title={`${album.title} - Cover`}
                    />
                }
                <div><Typography>Show Track Names</Typography><Switch checked={checked} onChange={handleChange} /></div>
            </CardContent>
            <Collapse in={checked} timeout="auto" unmountOnExit>
                <CardContent>
                    {albumSongs?.map((song) => {
                        return (
                            <Link to={`/the/songs#${song.rowKey}`}>
                                <Typography fontSize={20} color="orangered">{song.title}</Typography>
                            </Link>)
                    })}
                </CardContent>
            </Collapse>
        </Card >
    )
}