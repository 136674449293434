import { useEffect, useState } from 'react'
import axios from 'axios';
import { useLocation } from 'react-router';
import { PageTitle } from '../PageTitle';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const PageContent = () => {
    const [pageContent, setPageContent] = useState<string>("")
    const [pageDescription, setPageDescription] = useState<string>("")
    const location = useLocation()
    const [pageName, setPageName] = useState<string>(location.pathname.substring(1).replaceAll('/', '-'))


    useEffect(() => {
        function getContentInfo(name: string) {
            axios({
                method: 'GET',
                url: `/api/table-content/${pageName}`
            }).then(response => {
                setPageDescription(response.data.Description)
            }).catch(error => {
            })
        };
        getContentInfo(pageName)
    }, [pageName])

    useEffect(() => {
        function getContent(name: string) {
            axios({
                method: 'GET',
                url: `/api/content/${pageName}.md`
            }).then(response => {
                setPageContent(response.data)
            }).catch(error => {
                if (error.status === 404 || error.status === 500)
                    setPageName('default')
            })
        };
        getContent(pageName)
    }, [pageName])



    return (
        <>
            <div>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    children={pageContent}
                />
            </div>
        </>
    );
};